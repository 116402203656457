import Chart from 'chart.js/auto';
import 'chartjs-adapter-moment';
import Axios from 'axios';

$(document).ready(async () => {
    const canvas = $('#temp-logs-chart')
    if (canvas.length === 0) {
        return;
    }

    const dataUrl = canvas.data('url');
    const response = await Axios.get(dataUrl + ".json");

    const chart = new Chart(
      canvas,
        {
            type: 'line',
            data: {
                labels: [],
                datasets: [{
                    label: 'Температура екрану',
                    backgroundColor: '#00a950',
                    borderColor: '#00a950',
                    data: response.data.map((el) => { return {x: el.time, y: el.temp} }),
                }]
            },
            options: {
                scales: {
                    x: {
                        type: 'time',
                        time: {
                            tooltipFormat: 'DD.MM.YYYY HH:mm'
                        },
                        title: {
                            display: true,
                            text: 'Час'
                        }
                    },
                    y: {
                        title: {
                            display: true,
                            text: 'Температура'
                        },
                        min: 0,
                        max: 120
                    }
                }
            }
        }
    );
});
