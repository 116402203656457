require("@rails/ujs").start();
require("@rails/activestorage").start();
require("channels");
import 'trix/dist/trix.css';
import 'trix/dist/trix.js';
require("@rails/actiontext");
require("bootstrap/dist/js/bootstrap.bundle.min");
require("admin-lte/dist/js/adminlte.js");
require("@fortawesome/fontawesome-free/js/all.min");
import './temp-logs-charts';

import '../styles/main.scss';
